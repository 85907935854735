import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';

class SiteAnalytics extends React.Component {

  render() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header />

        <main className="flex-grow">

          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 md:pt-40 pb-16">
                <h1 className="h1 text-6xl font-headings mb-8 text-center" data-aos="fade-down" data-aos-delay="300">Website analytics</h1>
                <iframe 
                  title="Website analytics"
                  plausible-embed 
                  src="https://plausible.io/share/lattice.dev?auth=6sMwQDoiyiEa7DYRImcpo&embed=true&theme=light&background=%23ffffff" 
                  scrolling="no" 
                  frameborder="0" 
                  loading="lazy" 
                  style={{width: "1px", minWidth: "100%", height: "1600px"}}>
                </iframe>
                <script async src="https://plausible.io/js/embed.host.js"></script>
              </div>
            </div>
          </section>

        </main>
        <Footer />
      </div>
    );
  }
}

export default SiteAnalytics;