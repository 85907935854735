import React from 'react';

import Arrow from './Arrow';

function CtaButton(props) {
  return (<div>
  	{props.inverted ? (
	    <a className={props.small ? "btn-sm text-gray-900 bg-white hover:bg-gray-200" : "btn text-gray-900 bg-white hover:bg-gray-200"} href={props.url} target={props.external ? "blank_" : ""}>
	    	{props.label}
			<Arrow />
		</a>
	) : (
		<a className={props.small ? "btn-sm text-white bg-gray-900 hover:bg-gray-800" : "btn text-white bg-gray-900 hover:bg-gray-800"} href={props.url} target={props.external ? "blank_" : ""}>
		  {props.label}
		  <Arrow />
		</a>
	)}
  </div>)
}

export default CtaButton;
