import React from 'react';
import background from "../assets/img/codioful-formerly-gradienta-EgwxF98au6Y-unsplash2.jpg";

const navigation = [
  { name: 'Home', href: '/' },
  {
    name: 'MarketStream',
    href: 'https://marketstream.dev/',
    external: true,
    plausibleClass: "plausible-event-name=MarketStreamClicked"
  },
  {
    name: 'Maverick',
    href: 'https://withmaverick.com/',
    external: true,
    plausibleClass: "plausible-event-name=MaverickClicked"
  },
  {
    name: 'ReqBase',
    href: 'http://reqbase.dev/',
    external: true,
    plausibleClass: "plausible-event-name=ReqBaseClicked"
  },
  { name: 'Contact', href: 'mailto:team@lattice.dev', external: true, plausibleClass: "plausible-event-name=ContactClicked" },
  { name: 'LinkedIn', href: 'https://www.linkedin.com/company/latticedev/', external: true, plausibleClass: "plausible-event-name=LinkedInClicked" },
  { name: 'Admin', href: 'https://admin.lattice.dev/', external: true, plausibleClass: "plausible-event-name=AdminClicked" },
  { name: 'Terms Of Service', href: '/tos' },
  { name: 'Privacy Policy', href: '/privacy' },
]

export default function Footer(props) {
  return (
    <footer className="relative bg-cover bg-no-repeat bg-fixed bg-top" style={{ backgroundImage: `url(${background})` }}>
      <div className="bg-gradient-to-b from-black px-6 pb-24">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.map((item) => (
            <div key={item.name} className="px-3 py-2" data-aos="fade-up" data-aos-delay="350">
              <a href={item.href} className={"text-white opacity-75 hover:opacity-90 font-standard font-medium transition-all ease-in-out duration-200 " + item.plausibleClass} target={item.external ? "blank_" : ""}>
                {item.name}
              </a>
            </div>
          ))}
        </nav>

        <p className="mt-6 text-center text-gray-400 opacity-50" data-aos="fade-up" data-aos-delay="250">Lattice·dev — dev tools for cool devs.<br/>&copy; 2023 Lattice Data LLC. All rights reserved.</p>
      </div>
    </footer>
  )
}
