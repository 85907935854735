import React from 'react';
import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import Footer from '../partials/Footer';
import Cta from '../partials/Cta';
import InterstitialPhoto from '../partials/InterstitialPhoto';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative">
      <Header alwaysWhite={true} />

      <div className="relative">
        <HeroHome />
        <InterstitialPhoto />
        <Cta gridFooter={true} />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
