import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

const solutions = [
  {
    name: 'MarketStream',
    description: 'Broad, real-time stock market data delivered via a clean API.',
    href: 'https://marketstream.dev/',
    logoName: 'stocks-black.svg',
    external: true,
    plausibleClass: "plausible-event-name=MarketStreamClicked"
  },
  {
    name: 'Maverick',
    description: 'The copilot for Copilot. An automated layer of code review for GitHub PRs.',
    href: 'https://withmaverick.com/',
    logoName: 'maverick.svg',
    external: true,
    plausibleClass: "plausible-event-name=MaverickClicked"
  },
  {
    name: 'ReqBase',
    description: "A comprehensive online job postings aggregator accessible via API.",
    href: 'http://reqbase.dev/',
    logoName: 'job-black.svg',
    external: true,
    plausibleClass: "plausible-event-name=ReqBaseClicked"
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Dropdown() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-400' : 'text-gray-300',
              'group rounded-md inline-flex items-center font-medium hover:text-gray-400 focus:outline-none transition-all duration-200 mt-2 z-50'
            )}
          >
            <span>Products</span>
            <ChevronDownIcon
              className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="border border-gray-800 rounded absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
            >
              <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-black px-5 py-6 sm:gap-8 sm:p-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={item.external ? "blank_" : ""}
                      className={"-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 hover:bg-gray-900 transition-all duration-200 " + item.plausibleClass}
                    >
                      <img className="pt-1 w-8" src={require('../assets/svg/' + item.logoName)} alt={item.name} aria-hidden="true" />
                      <div className="ml-4">
                        <p className="font-medium text-gray-300 text-white">{item.name}</p>
                        <p className="mt-0.5 text-sm text-gray-400">{item.description}</p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

function Header(props) {

  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      if (window.pageYOffset > 10) {
        setTop(false)
      } else {
        setTop(true)
      } 
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]); 

  return (
    <header className={`px-4 sm:px-6 hidden sm:block fixed w-full z-30 transition duration-300 left-0 ease-in-out border-gray-800 w-screen ${!top && 'bg-black bg-opacity-80 shadow-lg border-b'}`}>
      <div className="max-w-6xl justify-between flex flex-row items-center mx-auto h-16 md:h-20" data-aos="zoom-y-out" data-aos-delay="200">
        <table>
          <tbody>
            <tr>
              <td>
                <Logo />
              </td>
              <td className="pl-6">
                <Dropdown />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex-row-reverse ">
          <a href="mailto:team@lattice.dev" className="btn-sm text-black bg-white hover:bg-gray-200 ml-3 plausible-event-name=ContactClicked">Contact Us</a>
          <a target="_blank" rel="noopener noreferrer" href="https://admin.lattice.dev/" className="text-white mr-4 mt-2 font-medium plausible-event-name=AdminClicked">Admin</a>
        </div>
      </div>
    </header>
  );
}

export default Header;
