import React from 'react';
import FeaturesHome from './FeaturesHome';
import PurpleText from '../utils/PurpleText';
import background from "../assets/img/codioful-formerly-gradienta-m_7p45JfXQo-unsplash.jpg";

export default function HeroHome() {

  return (
    <section className="bg-cover bg-no-repeat bg-fixed bg-top" style={{ backgroundImage: `url(${background})` }}>
      <div className="pt-32" style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
        <div className="bg-gradient-to-t from-black px-6">
          <div className="max-w-6xl px-3 text-center mx-auto md:pb-12">
            <img src={require("../assets/svg/logo.svg")} className="shadow-2xl shadow-black mx-auto mb-1 w-24 h-24 sm:w-32 sm:h-32 mb-6" alt="Maverick" data-aos="fade-down" data-aos-delay="200" />
            <h1 className="h1 max-w-4xl text-5xl mx-auto lg:text-6xl mb-4 font-extrabold font-headings" data-aos="fade-down" data-aos-delay="200"><PurpleText text="Lattice·dev" /></h1>
            <h2 className="h2 max-w-xl mx-auto mb-12" data-aos="fade-down" data-aos-delay="250"><PurpleText light={true} text="An esoteric bundle of dev tools for cool devs " /><span role="img" aria-label="smile">🤘🏻</span></h2>
          </div>

          <FeaturesHome />
        </div>
      </div>
    </section>
  );
}
