import React from 'react';

export default function Logo() {
  return (
  	<a href="/" className="flex">
	  <img src={require("../assets/svg/logo-white.svg")} className="w-10 h-10" alt="Lattice" />
      <h4 className="self-start h4 mt-1 ml-2">Lattice·dev</h4>
	</a>
  );
}

