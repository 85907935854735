import React from 'react';
import background from "../assets/img/codioful-formerly-gradienta-m_7p45JfXQo-unsplash.jpg";

export default function InterstitialPhoto() {
  return (
    <section className="px-6 py-3">
      <div className="max-w-6xl mx-auto bg-gradient-to-r from-indigo-800 via-purple-800 to-pink-800 p-1 rounded" data-aos="fade-right" data-aos-delay="300">
      	<div className="h-64 bg-cover bg-no-repeat bg-bottom rounded-sm" style={{ backgroundImage: `url(${background})` }}></div>
      </div>
    </section>
  );
}