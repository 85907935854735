import React from 'react';

function ProductLogo(props) {
  return (<div>
  	{props.url ? (
  		<div className="shadow w-10 hover:bg-gray-200">
		    <a href={props.url} target={props.external ? "blank_" : ""}>
			  <img className="p-2" src={require('../assets/svg/' + props.logoName)} width="40" alt={props.productName} aria-hidden="true" />
			</a>
		</div>
	) : (
		<div className="shadow w-10" >
		  <img className="p-2" src={require('../assets/svg/' + props.logoName)} width="40" alt={props.productName} aria-hidden="true" />
		</div>
	)}
  </div>)
}

export default ProductLogo;
