import React from 'react';
import CtaButton from '../utils/CtaButton';
import background from "../assets/svg/hexline-weave.svg";

export default function Cta(props) {
  return (
    <section className="px-6">
      <div className="max-w-6xl mx-auto pt-3 pb-16" data-aos="fade-up" data-aos-delay="300">
        <div className={"bg-gradient-to-r from-indigo-800 via-purple-800 to-pink-800 p-1 rounded-b-lg " + (props.gridFooter ? "rounded-t" : "rounded-t-lg")}>
          <div className={"py-10 px-8 md:py-16 md:px-12 shadow-2xl bg-cover bg-no-repeat rounded-b-md " + (props.gridFooter ? "rounded-t-sm" : "rounded-t-md")} style={{ backgroundImage: `url(${background})` }}>
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
                  <h2 className="h2 text-white mb-2 font-headings">Want to learn more?</h2>
                  <p className="text-lg text-purple-100 font-medium">We'd love to chat. Drop us a line and we'll get back to you soon.</p>
                </div>
                <CtaButton inverted={true} label="Get in touch" url="mailto:team@lattice.dev" />
            </div>
          </div>
        </div>
      </div>
    </section>

    
  );
}
