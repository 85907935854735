import React from 'react';
import Arrow from '../utils/Arrow';
import Checkmark from '../utils/Checkmark';
import ProductLogo from '../utils/ProductLogo';
import background from "../assets/svg/hexline-weave-dark.svg";

function FeatureHeader(props) {
  return (
    <div className={props.inverted ? "mb-4 text-center" : "mb-4 mx-2"}>
      <div className="inline-flex items-center">
        <ProductLogo logoName={props.logo} productName={props.name} />
        <div className={props.inverted ? "text-xl font-bold ml-1 text-white" : "text-xl font-bold ml-1 text-gray-200"}>{props.name}</div>
      </div>
      <div className={props.inverted ? "text-lg text-gray-100" : "text-lg text-gray-300"}>{props.description}</div>
    </div>
  );
}

function FeatureNotes(props) {
  var notes = []

  for (var index = 0; index < props.notes.length; index++) {
    var note = props.notes[index];
    notes.push(
      <li key={note} className="flex items-center mb-2">
        <Checkmark inverted={props.inverted} />
        <span className={props.inverted ? "ml-3 text-gray-200" : "ml-3"}>{note}</span>
      </li>
    )
  }

  return (
    <ul className="flex-grow text-gray-200 -mb-2">
      {notes}
    </ul>
  );
}

function LearnMoreButton(props) {
  return (
    <div className="border-t border-black pt-5 text-center">
      <a className={"btn-sm px-20 text-white bg-black border border-gray-300 hover:bg-gray-800 " + props.plausibleClass} href={props.path} target="blank_">
        Learn more
        <Arrow />
      </a>
    </div>
  )
}

function FeaturesHome(props) {

  return (
    <section className="relative">
      <div className="relative max-w-6xl mx-auto pb-3">
        <div className="mb-6 w-full h-full p-1 rounded-t-lg rounded-b bg-gradient-to-r from-indigo-800 via-purple-800 to-pink-800" data-aos="fade-down" data-aos-delay="300">
          <div className="w-full h-full p-6 bg-cover bg-no-repeat rounded-t-md rounded-b-sm" style={{ backgroundImage: `url(${background})` }}>
            <div className="mx-auto border-b border-black mb-6 pt-8">
              <FeatureHeader 
                name="MarketStream"
                description="Broad, real-time stock market data delivered via a clean API."
                logo="stocks-black.svg"
                inverted={true} />
            </div>
            <div className="max-w-3xl mx-auto grid gap-8 lg:grid-cols-3 sm:grid-cols-3 items-start mb-6">
              <div className="relative flex flex-col">
                <FeatureNotes notes={["Basic company information", "Real time stock price data", "Valuation metrics"]} inverted={true} />
              </div>
              <div className="relative flex flex-col">
                <FeatureNotes notes={["News coverage", "Stock similarity metrics", "Stock screener", "Financial statements"]} inverted={true} />
              </div>
              <div className="relative flex flex-col">
                <FeatureNotes notes={["Index and exchange composition", "Economic indicators", "Raw Yahoo Finance data"]} inverted={true} />
              </div>
            </div>
            <div className="max-w-lg mx-auto pb-8">
              <LearnMoreButton path="https://rapidapi.com/lattice-data-lattice-data-default/api/stock-market-data" plausibleClass="plausible-event-name=MarketStreamClicked" />
            </div>
          </div>
        </div>

        <div className="mx-auto grid gap-6 md:grid-cols-2">
          <div className="relative flex flex-col h-full p-1 bg-gradient-to-r from-indigo-800 via-purple-800 to-pink-800 md:to-purple-800 rounded" data-aos="fade-right" data-aos-delay="300">
            <div className="w-full h-full p-6 rounded-sm" style={{backgroundColor: "rgba(0, 0, 0, 0.85)"}}>
              <FeatureHeader 
                name="Maverick"
                description="The copilot for Copilot. An incremental layer of automated code review for your GitHub pull requests to catch more of the small issues that slip through the cracks."
                logo="maverick.svg" />
              <LearnMoreButton path="https://www.withmaverick.com/" plausibleClass="plausible-event-name=MaverickClicked" />
            </div>
          </div>

          <div className="relative flex flex-col h-full p-1 bg-gradient-to-r from-indigo-800 to-pink-800 md:from-purple-800 md:via-purple-800 rounded" data-aos="fade-left" data-aos-delay="300">
            <div className="w-full h-full p-6 rounded-sm" style={{backgroundColor: "rgba(0, 0, 0, 0.85)"}}>
              <FeatureHeader 
                name="ReqBase"
                description="The comprehensive job postings and requisitions aggregator. Coverage postings from job boards including Indeed, Monster, LinkedIn, Craigslist, SimplyHired, and Dice."
                logo="job-black.svg" />
              <LearnMoreButton path="http://reqbase.dev/" plausibleClass="plausible-event-name=ReqBaseClicked" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
